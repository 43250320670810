<template functional>
    <div v-on="listeners" v-bind="data.attrs" :class="(data.staticClass || '') + (data.class || '')" class="col chart-wrapper tw-rounded-lg tw-shadow tw-bg-white tw-pt-3 min-h-12">
        <div class="loader tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-between tw-items-center" v-if="props.isLoading">
            <span class="tw-text-gray-700 tw-text-base tw-font-normal tw-mt-1" style="font-size: 18px">{{ props.title }}</span>
            <nLoader class="tw-h-8 tw-w-8"></nLoader>
            <span></span>
        </div>
        <div v-else-if="!props.isLoading && !props.chartOptions.series[0].data.length" class="tw-flex tw-flex-col tw-h-full tw-justify-center tw-items-center">
            <span class="tw-text-gray-700 tw-text-base tw-font-normal tw-mt-1" style="font-size: 18px">{{ props.title }}</span>
            <span class="tw-text-center tw-mt-4 tw-block tw-py-28 tw-flex-1 tw-text-gray-500"> No Data Found </span>
        </div>

        <div v-else>
            <span @click="listeners.expandGraph(props.chartOptions)" class="chart__expand__wrapper icon tw-text-gray-400 hover:tw-text-blue-500 tw-cursor-pointer tw-absolute">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-maximize-2">
                    <polyline points="15 3 21 3 21 9" />
                    <polyline points="9 21 3 21 3 15" />
                    <line x1="21" y1="3" x2="14" y2="10" />
                    <line x1="3" y1="21" x2="10" y2="14" />
                </svg>
            </span>
            <highcharts width="100%" :options="props.chartOptions" />
        </div>
    </div>
</template>

<script>
import NLoader from "@/components/n-loader";

export default {
    components: {NLoader},
    props: {
        chartOptions: {type: Object},
        isLoading: {required: true, type: Boolean},
        title: String,
    },
};
</script>
