import VueMultiselect from "@/components/vue-multiselect";
import DatePicker from "vue2-datepicker";
import Pagination from "@/components/pagination";
import neoBadge from "@/components/badge";
import neoInput from "@/components/input";
// import Highcharts from "highcharts";
import titleMixin from "@/mixins/titleMixin";
import {VueGoodTable} from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import axios from "@/axios/index.js";
import asAxios from "../../axios/user";
import nLoader from "@/components/n-loader";
import {uniqBy, cloneDeep} from "lodash";
import "@/assets/css/multiselect.scss";
import {required} from "vuelidate/lib/validators";
import {object} from "underscore";
import users from "../users/users";
import {color} from "d3-color";
import {mapGetters, mapActions, mapState, mapMutations} from "vuex";
import Chart from "./~Chart.vue";
import NeoLoader from "@/components/loader";
import * as quicksight from "amazon-quicksight-embedding-sdk";
import {EventBus} from "@/main.js";

const ErrorMessage = () => import("@/components/server-error-message");

export default {
    title() {
        return `Case Dashboard`;
    },
    components: {
        VueMultiselect,
        "neo-datepicker": DatePicker,
        "neo-input": neoInput,
        neoBadge,
        VueGoodTable,
        nLoader,
        neoPagination: Pagination,
        Chart,
        ErrorMessage,
        NeoLoader,
    },
    validations: {
        filterName: {required},
    },
    props: {},
    mixins: [titleMixin],
    data() {
        return {
            embedUrl: "",
            qsDashboard: false,
            loadingDashboard: false,
            dashboard: null,
            frameOptions: {},
            contentOptions: {},
            dateRange: [],
            eventDateRange: [],
            selectedFilterCase: null,
            selected_subject_name: [],
            saved_selected_subject_name: [],
            case_id: [],
            selected_case: [],
            saved_selected_case: [],
            risk_categories: [],
            // reference_ids: ["ref1", "ref2"],
            // analysts: ["analysts1", "analysts2"],
            selected_risk_category: [],
            saved_selected_risk_category: [],
            // selected_analysts: [],
            // selected_reference_id: [],
            allEntities: [],
            subjectName: [],
            rag: [],
            entityType: [],
            ragStatus: [],
            selected_rag: [],
            saved_selected_rag: [],
            source_categories: [],
            selected_key_facts: [],
            selected_case_list: [],
            saved_selected_case_list: [],
            selected_entity_type: [],
            selected_rag_status: [],
            saved_selected_entity_type: [],
            case_lists: [],
            tableRows: [],
            // domains: [],
            case_type: [],
            selected_case_type: [],
            saved_selected_case_type: [],
            case_status: [],
            selected_case_status: [],
            saved_selected_case_status: [],
            // selected_domain: [],
            selected_type: [],
            totalRows: null,
            currentRow: 1,
            isLoading: true,
            isLoadingEntity: true,
            searchEntity: "",
            bookmarkFilters: [],
            bookmarkId: "",
            graphLoading: {
                chart1: true,
                chart2: true,
                chart3: true,
                chart4: true,
                chart5: true,
                chart6: true,
                chart7: true,
            },

            serverParams: {
                sort_by: "created_at",
                order_by: "desc",
            },
            riskParams: {
                case_id: "",
                entity: "",
                risk_categories: "",
                rag_status: "",
                domains: "",
                start_date: "",
                end_date: "",
            },

            entityColumns: [
                {
                    label: "Entity Name",
                    field: "entity",
                },
            ],

            columns: [
                {
                    label: "Event Type",
                    field: "entity_type",
                    width: "8rem",
                    sortable: true,
                },
                {
                    label: "Value",
                    field: "entity_value",
                    sortable: true,
                    width: "12rem",
                },
                // TO BE CHANGED ACCORDING TO TECH-3612
                {
                    label: "Entity Name",
                    field: "entity_name",
                    sortable: false,
                    width: "10rem",
                },
                // ADDED ACCORDING TO TECH-3619
                {
                    label: "Event RAG Status",
                    field: "rag_status",
                    sortable: true,
                    width: "6rem",
                },

                {
                    label: "Related To",
                    field: "relationship",
                    width: "10%",
                    sortable: false,
                },
                {
                    label: "Case ID",
                    field: "case_id",
                    width: "7rem",
                },
                // {
                //     label: "Outputs",
                //     field: "source_category",
                //     width: "20rem",
                // },
                {
                    label: "Event Category",
                    field: "risk_category",
                    sortable: false,
                    width: "15%",
                },
                {
                    label: "Customer Note",
                    field: "customer_note",
                    sortable: false,
                    width: "20%",
                },
                // {
                //     label: 'Source',
                //     field: 'source',
                //     sortable: false,

                // },
                // {
                //     label: "Source Link",
                //     field: "source_link",
                //     sortable: false,
                //     width: "7rem",
                // },
            ],
            // chart 1
            // chartOption1: {
            //     chart: {
            //         type: "pie",
            //         events: {},
            //         margin: [50, 0, 130, 0],
            //     },
            //     series: [
            //         {
            //             name: "Case RAG Status",
            //             // colorByPoint: true,
            //             data: [],
            //             events: {
            //                 click: (event) => {
            //                     this.filterByRag(event.point.name);
            //                 },
            //             },
            //             colors: ["#4572A7", "#AA4643", "#89A54E", "#80699B", "#3D96AE"],
            //             dataLabels: {
            //                 enabled: true,
            //                 distance: 25,
            //                 style: {
            //                     // fontSize: '8px',
            //                     color: "#898989",
            //                     alignTo: "connector",
            //                 },
            //                 useHTML: true,
            //                 format: `<div class='tw-flex tw-items-center'>
            //                     <span class='tw-font-extrabold tw-text-gray-600'>{point.y}</span>
            //                     <span class='tw-font-semibold tw-text-gray-400 tw-ml-2'>({point.percentage:.1f} %) </span>
            //                 </div> `,
            //             },
            //         },
            //     ],
            //     title: {
            //         text: "Case RAG Status",
            //         y: 10,
            //         style: {
            //             fontSize: "16px",
            //         },
            //     },
            //     legend: {
            //         // y: 10,
            //         margin: 10,
            //         padding: 12,
            //         enabled: true,
            //         layout: "horizontal",
            //         // align: 'right',
            //         // useHTML: true,
            //         floating: false,
            //         alignColumns: false,
            //         maxHeight: 90,
            //         itemStyle: {
            //             fontSize: 12,
            //             fontWeight: 400,
            //             marginBottom: 8,
            //         },
            //         itemWidth: 110,
            //     },
            //     plotOptions: {
            //         pie: {
            //             allowPointSelect: true,
            //             cursor: "pointer",
            //             showInLegend: true,
            //             innerSize: "60%",
            //             dataLabels: {
            //                 enabled: true,
            //                 format: "<b>{point.name}</b>: {point.value:.1f} %",
            //             },
            //             point: {
            //                 events: {
            //                     legendItemClick: (eve) => {
            //                         // eve.preventDefault()
            //                         this.filterByRag(eve.target.name);
            //                         return false;
            //                     },
            //                 },
            //             },
            //         },
            //     },
            //     credits: {
            //         enabled: false,
            //     },
            // },
            // chart 2
            // chartOption2: {
            //     chart: {
            //         type: "pie",
            //         plotBackgroundColor: null,
            //         plotBorderWidth: null,
            //         plotShadow: false,
            //         events: {},
            //         margin: [50, 0, 120, 0],
            //     },
            //     series: [
            //         {
            //             name: "Category",
            //             colorByPoint: true,
            //             data: [],
            //             events: {
            //                 click: (eve) => {
            //                     this.filterByRisk(eve.point.name);
            //                 },
            //             },
            //             dataLabels: {
            //                 enabled: true,
            //                 distance: 20,
            //                 style: {
            //                     // fontSize: '8px',
            //                     color: "#898989",
            //                     alignTo: "connector",
            //                 },
            //                 useHTML: true,
            //                 // format: "{point.name} : {point.y}",
            //                 format: `<div class='tw-flex tw-items-center'>
            //                     <span class='tw-font-extrabold tw-text-gray-600'>{point.y}</span>
            //                     <span class='tw-font-semibold tw-text-gray-400 tw-ml-2'>({point.percentage:.1f} %) </span>
            //                 </div> `,
            //             },
            //         },
            //     ],
            //     title: {
            //         text: "Category",
            //         y: 10,
            //     },
            //     legend: {
            //         // y: 10,
            //         margin: 10,
            //         padding: 12,
            //         enabled: true,
            //         layout: "horizontal",
            //         // align: 'right',
            //         // useHTML: true,
            //         floating: false,
            //         alignColumns: false,
            //         maxHeight: 90,
            //         itemStyle: {
            //             fontSize: 12,
            //             fontWeight: 400,
            //             marginBottom: 8,
            //         },
            //         itemWidth: 110,
            //     },
            //     plotOptions: {
            //         pie: {
            //             allowPointSelect: true,
            //             cursor: "pointer",
            //             showInLegend: true,
            //             innerSize: "60%",
            //             point: {
            //                 events: {
            //                     legendItemClick: (eve) => {
            //                         // eve.preventDefault()
            //                         this.filterByRisk(eve.target.name);
            //                         return false;
            //                     },
            //                 },
            //             },
            //         },
            //     },
            //     credits: {
            //         enabled: false,
            //     },
            // },
            // chart 3
            // chartOption3: {
            //     chart: {
            //         type: "pie",
            //         events: {},
            //         margin: [50, 0, 120, 0],
            //     },
            //     series: [
            //         {
            //             name: "Brands",
            //             colorByPoint: true,
            //             data: [],
            //             events: {
            //                 click: (eve) => {
            //                     this.filterBySource(eve.point.name);
            //                 },
            //             },
            //             dataLabels: {
            //                 enabled: true,
            //                 distance: 20,
            //                 style: {
            //                     color: "#898989",
            //                     alignTo: "connector",
            //                 },
            //                 format: "{point.percentage:.1f} %",
            //             },
            //         },
            //     ],

            //     title: {
            //         text: "Outputs",
            //         y: 10,
            //     },
            //     legend: {
            //         // y: 10,
            //         margin: 10,
            //         padding: 12,
            //         enabled: true,
            //         layout: "horizontal",
            //         // align: 'right',
            //         // useHTML: true,
            //         floating: false,
            //         alignColumns: false,
            //         maxHeight: 90,
            //         itemStyle: {
            //             fontSize: 12,
            //             fontWeight: 400,
            //             marginBottom: 8,
            //         },
            //         itemWidth: 110,
            //     },
            //     plotOptions: {
            //         pie: {
            //             allowPointSelect: true,
            //             cursor: "pointer",
            //             showInLegend: true,
            //             innerSize: "60%",
            //             dataLabels: {
            //                 enabled: false,
            //                 // format: "<b>{point.name}</b>: {point.load:.1f} %",
            //             },
            //             point: {
            //                 events: {
            //                     legendItemClick: (eve) => {
            //                         eve.preventDefault();
            //                         this.filterBySource(eve.target.name);
            //                         return false;
            //                     },
            //                 },
            //             },
            //         },
            //     },
            //     credits: {
            //         enabled: false,
            //     },
            // },
            // chart 4
            // chartOption4: {
            //     chart: {
            //         type: "pie",
            //         margin: [50, 0, 120, 0],
            //     },
            //     series: [
            //         {
            //             name: "Domains",
            //             colorByPoint: true,
            //             data: [
            //                 {
            //                     name: "Loading",
            //                     y: 100,
            //                 },
            //             ],
            //             events: {
            //                 click: (eve) => {
            //                     this.filterByDomain(eve.point.name);
            //                 },
            //             },
            //             dataLabels: {
            //                 enabled: true,
            //                 distance: 25,
            //                 style: {
            //                     // fontSize: '8px',
            //                     color: "#898989",
            //                     alignTo: "connector",
            //                 },
            //                 format: "{point.percentage:.1f} %",
            //             },
            //         },
            //     ],
            //     title: {
            //         text: "Domain",
            //         y: 10,
            //     },
            //     legend: {
            //         // y: 10,
            //         margin: 10,
            //         padding: 12,
            //         enabled: true,
            //         layout: "horizontal",
            //         floating: false,
            //         alignColumns: false,
            //         maxHeight: 90,
            //         itemStyle: {
            //             fontSize: 12,
            //             fontWeight: 400,
            //             marginBottom: 8,
            //         },
            //         itemWidth: 110,
            //     },
            //     plotOptions: {
            //         pie: {
            //             allowPointSelect: true,
            //             cursor: "pointer",
            //             showInLegend: true,
            //             innerSize: "60%",
            //             dataLabels: {
            //                 enabled: false,
            //                 format: "<b>{point.name}</b>: {point.load:.1f} %",
            //             },
            //             point: {
            //                 events: {
            //                     legendItemClick: (eve) => {
            //                         eve.preventDefault();
            //                         this.filterByDomain(eve.target.name);
            //                         return false;
            //                     },
            //                 },
            //             },
            //         },
            //     },
            //     credits: {
            //         enabled: false,
            //     },
            // },
            // chartOption5: {
            //     chart: {
            //         type: "pie",
            //         margin: [50, 0, 120, 0],
            //     },
            //     series: [
            //         {
            //             name: "Case State",
            //             colorByPoint: true,
            //             data: [
            //                 {
            //                     name: "Loading",
            //                     y: 100,
            //                 },
            //             ],
            //             events: {
            //                 click: (eve) => {
            //                     this.filterByCaseState(eve.point.name);
            //                 },
            //             },
            //             dataLabels: {
            //                 enabled: true,
            //                 distance: 25,
            //                 style: {
            //                     // fontSize: '8px',
            //                     color: "#898989",
            //                     alignTo: "connector",
            //                 },
            //                 useHTML: true,
            //                 format: `<div class='tw-flex tw-items-center'>
            //                     <span class='tw-font-extrabold tw-text-gray-600'>{point.y}</span>
            //                     <span class='tw-font-semibold tw-text-gray-400 tw-ml-2'>({point.percentage:.1f} %) </span>
            //                 </div> `,
            //             },
            //         },
            //     ],
            //     title: {
            //         text: "Case State",
            //         y: 10,
            //     },
            //     legend: {
            //         // y: 10,
            //         margin: 10,
            //         padding: 12,
            //         enabled: true,
            //         layout: "horizontal",
            //         floating: false,
            //         alignColumns: false,
            //         maxHeight: 90,
            //         itemStyle: {
            //             fontSize: 12,
            //             fontWeight: 400,
            //             marginBottom: 8,
            //         },
            //         itemWidth: 110,
            //     },
            //     plotOptions: {
            //         pie: {
            //             allowPointSelect: true,
            //             cursor: "pointer",
            //             showInLegend: true,
            //             innerSize: "60%",
            //             dataLabels: {
            //                 enabled: false,
            //                 format: "<b>{point.name}</b>: {point.load:.1f} %",
            //             },
            //             point: {
            //                 events: {
            //                     legendItemClick: (eve) => {
            //                         eve.preventDefault();
            //                         this.filterByCaseState(eve.target.name);
            //                         return false;
            //                     },
            //                 },
            //             },
            //         },
            //     },
            //     credits: {
            //         enabled: false,
            //     },
            // },
            // chartOption6: {
            //     chart: {
            //         type: "pie",
            //         margin: [50, 0, 120, 0],
            //     },
            //     series: [
            //         {
            //             name: "Case Type",
            //             colorByPoint: true,
            //             data: [
            //                 {
            //                     name: "Loading",
            //                     y: 100,
            //                 },
            //             ],
            //             events: {
            //                 click: (eve) => {
            //                     this.filterByCaseType(eve.point.name);
            //                 },
            //             },
            //             dataLabels: {
            //                 enabled: true,
            //                 distance: 25,
            //                 style: {
            //                     // fontSize: '8px',
            //                     color: "#898989",
            //                     alignTo: "connector",
            //                 },
            //                 useHTML: true,
            //                 format: `<div class='tw-flex tw-items-center'>
            //                     <span class='tw-font-extrabold tw-text-gray-600'>{point.y}</span>
            //                     <span class='tw-font-semibold tw-text-gray-400 tw-ml-2'>({point.percentage:.1f} %) </span>
            //                 </div> `,
            //             },
            //         },
            //     ],
            //     title: {
            //         text: "Case Type",
            //         y: 10,
            //     },
            //     legend: {
            //         // y: 10,
            //         margin: 10,
            //         padding: 12,
            //         enabled: true,
            //         layout: "horizontal",
            //         floating: false,
            //         alignColumns: false,
            //         maxHeight: 90,
            //         itemStyle: {
            //             fontSize: 12,
            //             fontWeight: 400,
            //             marginBottom: 8,
            //         },
            //         itemWidth: 110,
            //     },
            //     plotOptions: {
            //         pie: {
            //             allowPointSelect: true,
            //             cursor: "pointer",
            //             showInLegend: true,
            //             innerSize: "60%",
            //             dataLabels: {
            //                 enabled: false,
            //                 format: "<b>{point.name}</b>: {point.load:.1f} %",
            //             },
            //             point: {
            //                 events: {
            //                     legendItemClick: (eve) => {
            //                         eve.preventDefault();
            //                         this.filterByCaseType(eve.target.name);
            //                         return false;
            //                     },
            //                 },
            //             },
            //         },
            //     },
            //     credits: {
            //         enabled: false,
            //     },
            // },
            // chartOption7: {
            //     chart: {
            //         type: "line",
            //         zoomType: "x",
            //         events: {
            //             selection: this.selectionEvent,
            //         },
            //         resetZoomButton: {
            //             position: {
            //                 x: 999,
            //                 y: -999,
            //             },
            //         },
            //     },
            //     title: {
            //         text: "Timeline Chart",
            //     },

            //     xAxis: [
            //         {
            //             type: "datetime",
            //             labels: {
            //                 rotation: 45,
            //             },
            //         },
            //     ],
            //     yAxis: {
            //         title: {
            //             text: "Event Counts",
            //         },
            //     },
            //     legend: {
            //         enabled: false,
            //     },
            //     plotOptions: {
            //         area: {
            //             fillColor: {
            //                 linearGradient: {
            //                     x1: 0,
            //                     y1: 0,
            //                     x2: 0,
            //                     y2: 1,
            //                 },
            //                 stops: [
            //                     [0, Highcharts.getOptions().colors[0]],
            //                     // [
            //                     //   1,
            //                     //   Highcharts.color(Highcharts.getOptions().colors[0])
            //                     //     .setOpacity(0)
            //                     //     .get("rgba"),
            //                     // ],
            //                 ],
            //             },

            //             lineWidth: 1,
            //             states: {
            //                 hover: {
            //                     lineWidth: 1,
            //                 },
            //             },
            //             threshold: null,
            //         },
            //     },

            //     series: [
            //         {
            //             // type: "area",
            //             name: "Count",
            //             data: [],
            //         },
            //     ],
            //     credits: {
            //         enabled: false,
            //     },
            // },
            row_per_page: [10, 20, 30, 40, 50],
            perPage: 10,
            currentPage: 1,
            perPageEntity: 10,
            currentPageEntity: 1,
            total_records: null,
            isChartExpanded: false,
            chartExpanded: {},
            usersList: [],
            selected_user: [],
            saved_selected_user: [],
            users: [],
            loaderController: {
                loading: false,
                success: false,
            },
            openSaveFilter: false,
            selected_frequency: "",
            alertFrequency: ["Immediate", "Daily", "Weekly", "Monthly"],
            alerts: true,
            filterName: "",
            disable: true,
            openSavedFilters: false,
            dashboardSavedFilters: [],
            editDashboardFilter: false,
            filterId: "",
            deleteView: false,
            dashboardfilterId: "",
            charts: [],
            filterParams: [],
            bookmarkList: [],
            dashboardMapping: {
                Case_Dashboard: "Case Dashboard",
                Event_Dashboard: "Event Dashboard",
                Event_Internal_Dashboard: "Analyst Dashboard",
            },
        };
    },
    methods: {
        ...mapActions("dashboard", ["getCaseListPieChart", "getCaseStatusPieChart", "getDomainsPieChart", "getRegStatusPieChart", "getRiskCategoriesPieChart", "getSourceCategoriesPieChart", "getTimelineGraph"]),
        ...mapActions(["fetchPermissions"]),
        ...mapMutations("dashboard", ["SET_ASSIGNED_DASHBOARDS"]),
        async getCompanyDashboards() {
            const {data} = await axios.get(`/dashboard/${this.selectedAccount}/assigned-dashboards`);
            const dashboards = data.map((res, idx) => {
                if (idx == 0) {
                    return {
                        value: this.dashboardMapping[res.name],
                        visited: true,
                        dashboardId: res.quicksight_id ? res.quicksight_id : res.dashboard_id,
                    };
                } else {
                    return {
                        value: this.dashboardMapping[res.name],
                        visited: false,
                        dashboardId: res.quicksight_id ? res.quicksight_id : res.dashboard_id,
                    };
                }
            });
            this.SET_ASSIGNED_DASHBOARDS(dashboards);
        },
        /**
         * Calls quicksight dashboard API to get url
         */
        async handleDashboardView() {
            if (this.getAssignedDashboards.length > 0) {
                console.log(this.getAssignedDashboards);
                this.$router.push();
                // let result
                // if(this.$route?.query?.dashboard_id) {
                //     result = this.getAssignedDashboards.find(res=> res.dashboardId === this.$route.query.dashboard_id );
                //     if(result &&result.dashboardId){
                //         EventBus.$emit("handleSelectedDashboard",result.dashboardId)
                //     }
                // }
                // else
                //     result = this.getAssignedDashboards.find(res=> res.visited)
                // this.embedUrl = ''
                // this.loadingDashboard = true
                // this.qsDashboard = false
                // const url = "/quicksight/dashboard"
                // const payload = {
                //     email : this.getUserAuthDetails.email,
                //     company: this.getAccountId,
                //     dashboard_id: result.dashboardId
                // }
                // const {data} = await axios.post(url,payload)
                // if (data.url) {
                //     this.embedUrl = data.url
                //     this.qsDashboard = true
                // }
                // else {
                //     this.qsDashboard = false
                //     this.embedUrl = ''
                // }
                // this.loadingDashboard = false
                // await this.handleDashboardFilters()
                // await this.getBookmarks()
                // EventBus.$emit("handleBookmarkList",this.bookmarkList);
                // return this.embedUrl
            } else {
                this.loadingDashboard = false;
                this.qsDashboard = false;
                this.embedUrl = "";
            }
        },
        /**
         * To get filters applied on a dashboard view.
         */
        async getFilters() {
            const params = await this.dashboard?.getParameters();
            this.filterParams = params;
        },
        /**
         * Handler to set frame options for a dashboard and catch filter change events.
         */
        async handleDashboardFilters() {
            const embeddingContext = await quicksight.createEmbeddingContext();
            this.frameOptions = {
                url: this.embedUrl,
                container: "#quickSightFrame",
                onChange: (changeEvent, metadata) => {
                    switch (changeEvent.eventName) {
                        case "FRAME_MOUNTED": {
                            break;
                        }
                        case "FRAME_LOADED": {
                            break;
                        }
                    }
                },
            };
            this.contentOptions = {
                bookmarks: true,
                onMessage: async (messageEvent, experienceMetadata) => {
                    switch (messageEvent.eventName) {
                        case "CONTENT_LOADED": {
                            break;
                        }
                        case "ERROR_OCCURRED": {
                            break;
                        }
                        case "PARAMETERS_CHANGED": {
                            break;
                        }
                        case "SELECTED_SHEET_CHANGED": {
                            break;
                        }
                        case "SIZE_CHANGED": {
                            break;
                        }
                        case "MODAL_OPENED": {
                            window.scrollTo({
                                top: 0, // iframe top position
                            });
                            break;
                        }
                    }
                },
            };
            if (this.$route.query.bookmark_id || this.bookmarkFilters) this.contentOptions.parameters = this.bookmarkFilters.filters;
            this.dashboard = await embeddingContext.embedDashboard(this.frameOptions, this.contentOptions);
        },
        applyFilters() {
            this.serverParams.page = 1;
            this.serverParams.sort_by = "created_at";
            this.serverParams.order_by = "desc";
            this.initCharts();
            this.currentPage = 1;
            this.currentPageEntity = 1;
            this.getPagination();
            this.tableRows = [];
            this.allEntities = [];
            this.getAllEntities();
            this.getTableData();
            localStorage.setItem(
                "riskFilters",
                JSON.stringify({
                    case_id: this.selected_case,
                    case_state: this.selected_case_status,
                    case_type: this.selected_case_type,
                    subjectName: this.selected_subject_name,
                    risk_categories: this.selected_risk_category,
                    rag: this.selected_rag,
                    entity_type: this.selected_entity_type,
                    // sources: this.selected_key_facts,
                    dateRange: this.dateRange,
                    event_rag_status: this.selected_rag_status,
                    eventDateRange: this.eventDateRange,
                })
            );
        },

        disabledRange(date) {
            return date > new Date();
        },
        setDateRange(startDate, endDate) {
            this.dateRange = [];
            this.dateRange.push(startDate, endDate);
            this.$refs.datePicker.closePopup();
        },

        setThisMonth() {
            const today = new Date();
            this.setDateFilter(today.getDate() - 1);
        },
        setEventThisMonth() {
            const today = new Date();
            this.setEventDateFilter(today.getDate() - 1);
        },
        setThisWeek() {
            const today = new Date();
            this.setDateFilter(today.getDay() - 1);
        },
        setEventThisWeek() {
            const today = new Date();
            this.setEventDateFilter(today.getDay() - 1);
        },
        handleCaseType() {
            // this.updateFilters();
        },
        handleCaseState() {
            // this.updateFilters();
        },
        handleCaseUpdate(val) {
            // if (val !== this.selected_case)
            // this.updateFilters();
        },
        handleSubjectName() {
            // this.updateFilters();
        },
        handleRiskCat() {
            // this.updateFilters();
        },
        handleRag(val) {
            // this.updateFilters();
        },
        handleEntityType() {
            // this.updateFilters();
        },
        handleDatePicker() {
            // (date => {
            //     let dt = new Date(date)
            //     dt.setHours(0)
            //     dt.setMinutes(0)
            //     dt.setSeconds(0)
            //     dt.setMilliseconds(0)
            // })
            let today = new Date();
            let startDate = new Date(this.dateRange[0]);
            let endDate = new Date(this.dateRange[1]);
            startDate.setHours(0);
            startDate.setMinutes(0);
            startDate.setSeconds(0);
            startDate.setMilliseconds(0);

            //// SET END DATE TO HAVE CURRENT TIME
            endDate.setHours(23);
            endDate.setMinutes(59);
            endDate.setSeconds(59);
            endDate.setMilliseconds(999);
            // endDate.setUTCHours(23,59,59,999);
            this.dateRange = [Date.parse(startDate), Date.parse(endDate)];
            this.updateFilters();
        },
        handleEventDatePicker() {
            // (date => {
            //     let dt = new Date(date)
            //     dt.setHours(0)
            //     dt.setMinutes(0)
            //     dt.setSeconds(0)
            //     dt.setMilliseconds(0)
            // })
            let today = new Date();
            let startDate = new Date(this.eventDateRange[0]);
            let endDate = new Date(this.eventDateRange[1]);
            startDate.setHours(0);
            startDate.setMinutes(0);
            startDate.setSeconds(0);
            startDate.setMilliseconds(0);

            //// SET END DATE TO HAVE CURRENT TIME
            endDate.setHours(23);
            endDate.setMinutes(59);
            endDate.setSeconds(59);
            endDate.setMilliseconds(999);
            // endDate.setUTCHours(23,59,59,999);
            this.eventDateRange = [Date.parse(startDate), Date.parse(endDate)];
            this.updateFilters();
        },

        handleEditDatePicker() {
            //this.saved_dateRange =[]
            let today = new Date();
            let startDate = new Date(this.saved_dateRange[0]);
            let endDate = new Date(this.saved_dateRange[1]);
            startDate.setHours(0);
            startDate.setMinutes(0);
            startDate.setSeconds(0);
            startDate.setMilliseconds(0);

            //// SET END DATE TO HAVE CURRENT TIME
            endDate.setHours(23);
            endDate.setMinutes(59);
            endDate.setSeconds(59);
            endDate.setMilliseconds(999);
            this.saved_dateRange = [Date.parse(startDate), Date.parse(endDate)];
            this.updateFilters();
        },
        // removeFilter() {
        //     this.serverParams.page = 1
        //     this.initCharts()
        //     this.getTableData()
        // },

        // initCharts() {
        // this.initCharts();

        // this.initChart1();
        // this.initChart2();
        // // this.initChart3();
        // // this.initChart4();
        // this.initChart5();
        // this.initChart6();

        // let charts = ["chartOption1", "chartOption2", "chartOption5", "chartOption6"];
        // charts.forEach((chart) => {
        //     this[chart].colors = ["#6fc2ff", "#42dca1", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"];
        // });
        // },
        initCharts: async function initCharts() {
            // if (!initCharts.charts) {
            const componentInstance = this;
            const chartDataRequestOptions = {
                case_id: this.selected_case,
                risk_categories: this.selected_risk_category,
                // domains: this.selected_domain,
                rag_status: this.selected_rag,
                entity: this.selected_subject_name,
                // source_categories: this.selected_key_facts,
                start_date: this.dateRange[0],
                end_date: this.dateRange[1],
                case_type: this.selected_case_type,
                case_status: this.selected_case_status,
                entity_type: this.selected_entity_type ? this.selected_entity_type.map((el) => el.value) : [],
                event_rag_status: this.selected_rag_status,
                event_start_date: this.eventDateRange[0],
                event_end_date: this.eventDateRange[1],
            };

            function generateChart(props, initBody) {
                if (!props.isLoading) throw new Error("invalid chart, loading model not exists");

                async function init() {
                    props.isLoading = true;
                    await initBody(props);
                    props.isLoading = false;
                }

                return {props, init};
            }

            initCharts.charts = [
                generateChart(
                    {
                        isLoading: true,
                        title: "Case RAG Status",
                        chartOptions: {
                            chart: {
                                type: "pie",
                                events: {},
                                margin: [50, 0, 100, 0],
                            },
                            series: [
                                {
                                    name: "Case RAG Status",
                                    // colorByPoint: true,
                                    data: [],
                                    events: {
                                        click: (event) => {
                                            this.filterByRag(event.point.name);
                                        },
                                    },
                                    colors: ["#4572A7", "#AA4643", "#89A54E", "#80699B", "#3D96AE"],
                                    dataLabels: {
                                        enabled: true,
                                        distance: 25,
                                        style: {
                                            // fontSize: '8px',
                                            color: "#898989",
                                            alignTo: "connector",
                                        },
                                        useHTML: true,
                                        format: `<div class='tw-flex tw-items-center'>
                                                <span class='tw-font-extrabold tw-text-gray-600'>{point.y}</span>
                                                <span class='tw-font-semibold tw-text-gray-400 tw-ml-2'>({point.percentage:.1f} %) </span>
                                            </div> `,
                                    },
                                },
                            ],
                            title: {
                                text: "Case RAG Status",
                                y: 10,
                                style: {
                                    fontSize: "16px",
                                },
                            },
                            legend: {
                                // y: 10,
                                margin: 10,
                                padding: 12,
                                enabled: true,
                                layout: "horizontal",
                                // align: 'right',
                                // useHTML: true,
                                floating: false,
                                alignColumns: false,
                                maxHeight: 90,
                                itemStyle: {
                                    fontSize: 12,
                                    fontWeight: 400,
                                    marginBottom: 8,
                                },
                                itemWidth: 110,
                            },
                            plotOptions: {
                                pie: {
                                    allowPointSelect: true,
                                    cursor: "pointer",
                                    showInLegend: true,
                                    innerSize: "60%",
                                    dataLabels: {
                                        enabled: true,
                                        format: "<b>{point.name}</b>: {point.value:.1f} %",
                                    },
                                    point: {
                                        events: {
                                            legendItemClick: (eve) => {
                                                // eve.preventDefault()
                                                this.filterByRag(eve.target.name);
                                                return false;
                                            },
                                        },
                                    },
                                },
                            },
                            credits: {
                                enabled: false,
                            },
                        },
                    },
                    async function({chartOptions}) {
                        await componentInstance.getRegStatusPieChart(chartDataRequestOptions);
                        chartOptions.series[0].data = componentInstance.ragStatusPieChart.map((el) => ({
                            name: el._id,
                            y: el.value,
                            color: el._id?.toLowerCase() == "red" ? "#e95058" : el._id?.toLowerCase() == "green" ? "#42dca1" : el._id?.toLowerCase() == "amber" ? "#FFBF00" : "#898989",
                        }));
                    }
                ),
                generateChart(
                    {
                        isLoading: true,
                        title: "Case State",
                        chartOptions: {
                            chart: {
                                type: "pie",
                                margin: [50, 0, 100, 0],
                            },
                            series: [
                                {
                                    name: "Case State",
                                    colorByPoint: true,
                                    data: [
                                        {
                                            name: "Loading",
                                            y: 100,
                                        },
                                    ],
                                    events: {
                                        click: (eve) => {
                                            this.filterByCaseState(eve.point.name);
                                        },
                                    },
                                    dataLabels: {
                                        enabled: true,
                                        distance: 25,
                                        style: {
                                            // fontSize: '8px',
                                            color: "#898989",
                                            alignTo: "connector",
                                        },
                                        useHTML: true,
                                        format: `<div class='tw-flex tw-items-center'>
                                                <span class='tw-font-extrabold tw-text-gray-600'>{point.y}</span>
                                                <span class='tw-font-semibold tw-text-gray-400 tw-ml-2'>({point.percentage:.1f} %) </span>
                                            </div> `,
                                    },
                                },
                            ],
                            title: {
                                text: "Case State",
                                y: 10,
                            },
                            legend: {
                                // y: 10,
                                margin: 10,
                                padding: 12,
                                enabled: true,
                                layout: "horizontal",
                                floating: false,
                                alignColumns: false,
                                maxHeight: 90,
                                itemStyle: {
                                    fontSize: 12,
                                    fontWeight: 400,
                                    marginBottom: 8,
                                },
                                itemWidth: 110,
                            },
                            plotOptions: {
                                pie: {
                                    allowPointSelect: true,
                                    cursor: "pointer",
                                    showInLegend: true,
                                    innerSize: "60%",
                                    dataLabels: {
                                        enabled: false,
                                        format: "<b>{point.name}</b>: {point.load:.1f} %",
                                    },
                                    point: {
                                        events: {
                                            legendItemClick: (eve) => {
                                                eve.preventDefault();
                                                this.filterByCaseState(eve.target.name);
                                                return false;
                                            },
                                        },
                                    },
                                },
                            },
                            credits: {
                                enabled: false,
                            },
                        },
                    },
                    async function({chartOptions}) {
                        await componentInstance.getCaseStatusPieChart(chartDataRequestOptions);

                        chartOptions.series[0].data = componentInstance.caseStatusPieChart.map((el) => ({
                            name: el._id,
                            y: el.value,
                        }));
                    }
                ),
                // generateChart(
                //     {
                //         isLoading: true,
                //         title: "Case Type",
                //         chartOptions: {
                //             chart: {
                //                 type: "pie",
                //                 margin: [50, 0, 120, 0],
                //             },
                //             series: [
                //                 {
                //                     name: "Case Type",
                //                     colorByPoint: true,
                //                     data: [
                //                         {
                //                             name: "Loading",
                //                             y: 100,
                //                         },
                //                     ],
                //                     events: {
                //                         click: (eve) => {
                //                             this.filterByCaseType(eve.point.name);
                //                         },
                //                     },
                //                     dataLabels: {
                //                         enabled: true,
                //                         distance: 25,
                //                         style: {
                //                             // fontSize: '8px',
                //                             color: "#898989",
                //                             alignTo: "connector",
                //                         },
                //                         useHTML: true,
                //                         format: `<div class='tw-flex tw-items-center'>
                //                                 <span class='tw-font-extrabold tw-text-gray-600'>{point.y}</span>
                //                                 <span class='tw-font-semibold tw-text-gray-400 tw-ml-2'>({point.percentage:.1f} %) </span>
                //                             </div> `,
                //                     },
                //                 },
                //             ],
                //             title: {
                //                 text: "Case Type",
                //                 y: 10,
                //             },
                //             legend: {
                //                 // y: 10,
                //                 margin: 10,
                //                 padding: 12,
                //                 enabled: true,
                //                 layout: "horizontal",
                //                 floating: false,
                //                 alignColumns: false,
                //                 maxHeight: 90,
                //                 itemStyle: {
                //                     fontSize: 12,
                //                     fontWeight: 400,
                //                     marginBottom: 8,
                //                 },
                //                 itemWidth: 110,
                //             },
                //             plotOptions: {
                //                 pie: {
                //                     allowPointSelect: true,
                //                     cursor: "pointer",
                //                     showInLegend: true,
                //                     innerSize: "60%",
                //                     dataLabels: {
                //                         enabled: false,
                //                         format: "<b>{point.name}</b>: {point.load:.1f} %",
                //                     },
                //                     point: {
                //                         events: {
                //                             legendItemClick: (eve) => {
                //                                 eve.preventDefault();
                //                                 this.filterByCaseType(eve.target.name);
                //                                 return false;
                //                             },
                //                         },
                //                     },
                //                 },
                //             },
                //             credits: {
                //                 enabled: false,
                //             },
                //         },
                //     },
                //     async function({ chartOptions }) {
                //         await componentInstance.getCaseListPieChart(chartDataRequestOptions);
                //         chartOptions.series[0].data = componentInstance.caseListPieChart.map((el) => ({
                //             name: el._id,
                //             y: el.value,
                //         }));
                //     }
                // ),
                generateChart(
                    {
                        isLoading: true,
                        title: "Event Category",
                        chartOptions: {
                            chart: {
                                type: "pie",
                                plotBackgroundColor: null,
                                plotBorderWidth: null,
                                plotShadow: false,
                                events: {},
                                margin: [50, 0, 100, 0],
                            },
                            series: [
                                {
                                    name: "Event Category",
                                    colorByPoint: true,
                                    data: [],
                                    events: {
                                        click: (eve) => {
                                            this.filterByRisk(eve.point.name);
                                        },
                                    },
                                    dataLabels: {
                                        enabled: true,
                                        distance: 20,
                                        style: {
                                            // fontSize: '8px',
                                            color: "#898989",
                                            alignTo: "connector",
                                        },
                                        useHTML: true,
                                        // format: "{point.name} : {point.y}",
                                        format: `<div class='tw-flex tw-items-center'>
                                                <span class='tw-font-extrabold tw-text-gray-600'>{point.y}</span>
                                                <span class='tw-font-semibold tw-text-gray-400 tw-ml-2'>({point.percentage:.1f} %) </span>
                                            </div> `,
                                    },
                                },
                            ],
                            title: {
                                text: "Event Category",
                                y: 10,
                            },
                            legend: {
                                // y: 10,
                                margin: 10,
                                padding: 12,
                                enabled: true,
                                layout: "horizontal",
                                // align: 'right',
                                // useHTML: true,
                                floating: false,
                                alignColumns: false,
                                maxHeight: 90,
                                itemStyle: {
                                    fontSize: 12,
                                    fontWeight: 400,
                                    marginBottom: 8,
                                },
                                itemWidth: 110,
                            },
                            plotOptions: {
                                pie: {
                                    allowPointSelect: true,
                                    cursor: "pointer",
                                    showInLegend: true,
                                    innerSize: "60%",
                                    point: {
                                        events: {
                                            legendItemClick: (eve) => {
                                                // eve.preventDefault()
                                                this.filterByRisk(eve.target.name);
                                                return false;
                                            },
                                        },
                                    },
                                },
                            },
                            credits: {
                                enabled: false,
                            },
                        },
                    },
                    async function({chartOptions}) {
                        await componentInstance.getRiskCategoriesPieChart(chartDataRequestOptions);
                        chartOptions.series[0].data = componentInstance.riskCategoriesPieChart.map((el) => ({
                            name: el._id,
                            y: el.value,
                        }));
                    }
                ),
            ];

            this.charts = initCharts.charts.map((chart) => chart.props);
            this.charts.forEach((_, idx) => {
                this.charts[idx].chartOptions.colors = ["#6fc2ff", "#42dca1", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"];
            });
            // }

            initCharts.charts.forEach((chart) => chart.init());
        },

        openViewFilter() {
            if (this.openSaveFilter) this.openSaveFilter = false;
            else this.openSaveFilter = true;
        },
        filterByRisk(val) {
            let risk = val;
            if (!this.selected_risk_category.includes(risk)) {
                this.selected_risk_category.push(risk);
            } else {
                this.selected_risk_category = this.selected_risk_category.filter((e) => e !== risk);
            }
            // this.updateFilters();
            // CLEAR SELECTED FILTERS TO BE UPDATED BASED ON API RESULT
            this.filterOutFilterVals();
            this.initCharts();
            this.currentPage = 1;
            this.currentPageEntity = 1;
            this.getPagination();
            this.tableRows = [];
            this.allEntities = [];
            this.getAllEntities();
            this.getTableData();
        },
        filterByRag(name) {
            let rag = name;
            if (!this.selected_rag.includes(rag)) {
                this.selected_rag.push(rag);
            } else {
                this.selected_rag = this.selected_rag.filter((e) => e !== rag);
            }
            // this.updateFilters();
            // CLEAR SELECTED FILTERS TO BE UPDATED BASED ON API RESULT
            this.filterOutFilterVals();
            this.initCharts();
            this.currentPage = 1;
            this.currentPageEntity = 1;
            this.getPagination();
            this.tableRows = [];
            this.allEntities = [];
            this.getAllEntities();
            this.getTableData();
        },

        // filterByDomain(val) {
        //     let domain = val;
        //     if (!this.selected_domain.includes(domain)) {
        //         this.selected_domain.push(domain);
        //     } else {
        //         this.selected_domain = this.selected_domain.filter((e) => e !== domain);
        //     }
        //     this.updateFilters();
        //     this.initCharts();
        //     this.currentPage = 1;
        //     this.getPagination();
        //     this.tableRows = [];
        //     this.getTableData();
        // },
        filterByCaseState(val) {
            let case_state = val;
            if (!this.selected_case_status.includes(case_state)) {
                this.selected_case_status.push(case_state);
            } else {
                console.log("casesatus");
                this.selected_case_status = this.selected_case_status.filter((e) => e !== case_state);
            }
            // this.updateFilters();
            // CLEAR SELECTED FILTERS TO BE UPDATED BASED ON API RESULT
            this.filterOutFilterVals();
            this.initCharts();
            this.currentPage = 1;
            this.currentPageEntity = 1;
            this.getPagination();
            this.tableRows = [];
            this.allEntities = [];
            this.getAllEntities();
            this.getTableData();
        },
        cleartext(inputtype) {
            this.$refs[inputtype].clearAll();
        },

        filterByCaseType(val) {
            let caseType = val;
            if (!this.selected_case_type.includes(caseType)) {
                this.selected_case_type.push(caseType);
            } else {
                this.selected_case_type = this.selected_case_type.filter((e) => e !== caseType);
            }
            // this.updateFilters();
            // CLEAR SELECTED FILTERS TO BE UPDATED BASED ON API RESULT
            this.filterOutFilterVals();
            this.initCharts();
            this.currentPage = 1;
            this.currentPageEntity = 1;
            this.getPagination();
            this.tableRows = [];
            this.allEntities = [];
            this.getAllEntities();
            this.getTableData();
        },
        filterBySource(val) {
            let source = val;
            if (!this.selected_key_facts.includes(source)) {
                this.selected_key_facts.push(source);
            } else {
                this.selected_key_facts = this.selected_domain.filter((e) => e !== source);
            }
            // this.updateFilters();
            // CLEAR SELECTED FILTERS TO BE UPDATED BASED ON API RESULT
            this.filterOutFilterVals();
            this.initCharts();
            this.currentPage = 1;
            this.currentPageEntity = 1;
            this.getPagination();
            this.tableRows = [];
            this.allEntities = [];
            this.getAllEntities();
            this.getTableData();
        },
        dateHandler() {},
        // selectionEvent(event) {
        //     this.setDateRange(Math.floor(event.xAxis[0].min), Math.floor(event.xAxis[0].max));
        //     this.initCharts();
        //     this.currentPage = 1;
        //     this.getPagination();
        //     this.tableRows = [];
        //     this.getTableData();
        // },

        async getAllEntities() {
            this.isLoadingEntity = true;
            const {data} = await axios.post("dashboard/table/entity-list", {
                case_id: this.selected_case,
                entity: this.selected_subject_name,
                risk_categories: this.selected_risk_category,
                rag_status: this.selected_rag,
                case_type: this.selected_case_type,
                case_status: this.selected_case_status,
                start_date: this.dateRange[0],
                end_date: this.dateRange[1],
                entity_type: this.selected_entity_type ? this.selected_entity_type.map((el) => el.value) : [],
                // domains: this.selected_domain,
                // source_categories: this.selected_key_facts,
                event_rag_status: this.selected_rag_status,
            });
            // this.resetFilter()
            let {entities} = data;
            this.allEntities = entities;
            this.isLoadingEntity = true;
            // entities.forEach((el) => {
            //     this.allEntities.push({
            //         entity: el,
            //     })
            // });
        },
        async updateFilters() {
            const {data} = await axios.post("dashboard/filters", {
                start_date: this.dateRange[0],
                end_date: this.dateRange[1],
            });
            // this.resetFilter()
            let {case_ids, entities, risk_categories, rag, case_type, case_status, entity_type, event_rag_status} = data?.data;
            this.case_id = case_ids.sort((cs1, cs2) => cs1.split("-")[1] - cs2.split("-")[1]);
            this.subjectName = entities;
            this.risk_categories = risk_categories;
            // this.source_categories = source_categories
            this.rag = rag;
            // this.domains = domains
            this.case_type = case_type;
            this.case_status = case_status;
            this.entityType = entity_type.map((el) => ({
                label: el
                    .split("_")
                    .map((word) => word[0].toUpperCase() + word.substr(1))
                    .join(" "),
                value: el,
            }));
            this.ragStatus = event_rag_status;

            // CLEAR SELECTED FILTERS TO BE UPDATED BASED ON API RESULT
            this.filterOutFilterVals();
        },

        /**
         * Clear out filters
         */
        filterOutFilterVals() {
            this.selected_case = this.selected_case.filter((el) => this.case_id.includes(el));
            this.selected_case_status = this.selected_case_status.filter((el) => this.case_status.includes(el));
            this.selected_case_type = this.selected_case_type.filter((el) => this.case_type.includes(el));
            this.selected_entity_type = this.selected_entity_type.filter((el) => this.entityType.some((en) => en.value === el.value));
            this.selected_risk_category = this.selected_risk_category.filter((el) => this.risk_categories.includes(el));
            this.selected_rag = this.selected_rag.filter((el) => this.rag.includes(el));
            this.selected_subject_name = this.selected_subject_name.filter((el) => this.subjectName.includes(el));
            this.selected_rag_status = this.selected_rag_status.filter((el) => this.ragStatus.includes(el));
            // console.log('this.selected_entity_type :>> ', this.selected_entity_type, "entityType", this.entityType);
        },
        // GET PAGINATION AND TOTAL RECORDS AVAILABLE FOR TABLE
        async getPagination() {
            const {data} = await axios.post("dashboard/table/stash", {
                case_id: this.selected_case,
                entity: this.selected_subject_name,
                entity_type: this.selected_entity_type ? this.selected_entity_type.map((el) => el.value) : [],
                risk_categories: this.selected_risk_category,
                rag_status: this.selected_rag,
                domains: this.selected_domain,
                source_categories: this.selected_key_facts,
                start_date: this.dateRange[0],
                end_date: this.dateRange[1],
                case_type: this.selected_case_type,
                case_status: this.selected_case_status,
                page: 1,
                skip: 0,
                order_by: "desc",
                event_rag_status: this.selected_rag_status,
            });
            this.total_records = data.total_records;
        },
        // reset filters //
        resetFilter() {
            this.currentPage = 1;
            this.currentPageEntity = 1;
            this.perPage = 10;
            this.perPageEntity = 10;
            this.serverParams.sort_by = "created_at";
            this.serverParams.order_by = "asc";
            this.selected_case = [];
            this.selected_subject_name = [];
            this.selected_rag = [];
            this.selected_rag_status = [];
            this.selected_risk_category = [];
            this.selected_key_facts = [];
            this.selected_domain = [];
            this.selected_case_type = [];
            this.selected_case_status = [];
            this.selected_case_list = [];
            this.selected_entity_type = [];
            this.eventDateRange = [];
            this.setDateFilter(7);
            this.initCharts();
            // this.getAllFilters()
            this.updateFilters();
            this.getPagination();
            this.tableRows = [];
            this.allEntities = [];
            this.getAllEntities();
            this.getTableData();
            localStorage.removeItem("riskFilters");
            // this.$refs.domains.search = "";
            this.$refs.types.search = "";
            this.$refs.entities.search = "";
            this.$refs.risk_cat.search = "";
            this.$refs.case_id.search = "";
            this.$refs.types.search = "";
            this.$refs.status.search = "";
            this.$refs.type.search = "";
            // this.$refs.product.search = "";
            // this.$refs.sources.search = "";
            this.$refs.rag.search = "";
            // this.$refs.chart1.chart.xAxis[0].setExtremes();
            this.disable = true;
        },
        // async initChart1() {
        //     this.graphLoading.chart1 = true;
        //     const { data } = await axios.post("dashboard/pie/rag-status", {
        //         case_id: this.selected_case,
        //         risk_categories: this.selected_risk_category,
        //         // domains: this.selected_domain,
        //         rag_status: this.selected_rag,
        //         entity: this.selected_subject_name,
        //         // source_categories: this.selected_key_facts,
        //         start_date: this.dateRange[0],
        //         end_date: this.dateRange[1],
        //         case_type: this.selected_case_type,
        //         case_status: this.selected_case_status,
        //         entity_type: this.selected_entity_type ? this.selected_entity_type.map((el) => el.value) : [],
        //     });
        //     this.chartOption1.series[0].data = data.rag_status.map((el) => ({
        //         name: el._id,
        //         y: el.value,
        //         color: el._id?.toLowerCase() == "red" ? "#e95058" : el._id?.toLowerCase() == "green" ? "#42dca1" : el._id?.toLowerCase() == "amber" ? "#FFBF00" : "#898989",
        //     }));
        //     this.graphLoading.chart1 = false;
        // },
        // async initChart2() {
        //     this.graphLoading.chart2 = true;
        //     const { data } = await axios.post("dashboard/pie/risk-categories", {
        //         case_id: this.selected_case,
        //         risk_categories: this.selected_risk_category,
        //         rag_status: this.selected_rag,
        //         // domains: this.selected_domain,
        //         entity: this.selected_subject_name,
        //         // source_categories: this.selected_key_facts,
        //         start_date: this.dateRange[0],
        //         end_date: this.dateRange[1],
        //         case_type: this.selected_case_type,
        //         case_status: this.selected_case_status,
        //         entity_type: this.selected_entity_type ? this.selected_entity_type.map((el) => el.value) : [],
        //     });
        //     this.chartOption2.series[0].data = data.risk_categories.map((el) => ({
        //         name: el._id,
        //         y: el.value,
        //     }));
        //     this.graphLoading.chart2 = false;
        // },
        // async initChart3() {
        //     this.graphLoading.chart3 = true;

        //     const { data } = await axios.post("dashboard/pie/source-categories", {
        //         case_id: this.selected_case,
        //         risk_categories: this.selected_risk_category,
        //         rag_status: this.selected_rag,
        //         domains: this.selected_domain,
        //         entity: this.selected_subject_name,
        //         source_categories: this.selected_key_facts,
        //         start_date: this.dateRange[0],
        //         end_date: this.dateRange[1],
        //         case_type: this.selected_case_type,
        //         case_status: this.selected_case_status,
        //     });
        //     this.chartOption3.series[0].data = data.source_categories.map((el) => ({
        //         name: el._id,
        //         y: el.value,
        //     }));
        //     this.graphLoading.chart3 = false;
        // },
        // async initChart4() {
        //     this.graphLoading.chart4 = true;
        //     const { data } = await axios.post("dashboard/pie/domains", {
        //         case_id: this.selected_case,
        //         risk_categories: this.selected_risk_category,
        //         rag_status: this.selected_rag,
        //         entity: this.selected_subject_name,
        //         domains: this.selected_domain,
        //         source_categories: this.selected_key_facts,
        //         // start_date: this.dateRange[0],
        //         // end_date:this.dateRange[1],
        //         start_date: this.dateRange[0],
        //         end_date: this.dateRange[1],
        //         case_type: this.selected_case_type,
        //         case_status: this.selected_case_status,
        //     });
        //     this.chartOption4.series[0].data = data.domains.map((el) => ({
        //         name: el._id,
        //         y: el.value,
        //     }));
        //     this.graphLoading.chart4 = false;
        // },
        // async initChart5() {
        //     this.graphLoading.chart6 = true;
        //     const { data } = await axios.post("dashboard/pie/case-status", {
        //         case_id: this.selected_case,
        //         risk_categories: this.selected_risk_category,
        //         rag_status: this.selected_rag,
        //         entity: this.selected_subject_name,
        //         // domains: this.selected_domain,
        //         case_type: this.selected_case_type,
        //         case_status: this.selected_case_status,
        //         // source_categories: this.selected_key_facts,
        //         start_date: this.dateRange[0],
        //         end_date: this.dateRange[1],
        //         entity_type: this.selected_entity_type ? this.selected_entity_type.map((el) => el.value) : [],
        //     });
        //     this.chartOption5.series[0].data = data.cases.map((el) => ({
        //         name: el._id,
        //         y: el.value,
        //     }));
        //     this.graphLoading.chart6 = false;
        // },
        // async initChart6() {
        //     this.graphLoading.chart7 = true;
        //     const { data } = await axios.post("dashboard/pie/case-list", {
        //         case_id: this.selected_case,
        //         risk_categories: this.selected_risk_category,
        //         rag_status: this.selected_rag,
        //         entity: this.selected_subject_name,
        //         // domains: this.selected_domain,
        //         case_type: this.selected_case_type,
        //         case_status: this.selected_case_status,
        //         // source_categories: this.selected_key_facts,
        //         start_date: this.dateRange[0],
        //         end_date: this.dateRange[1],
        //         entity_type: this.selected_entity_type ? this.selected_entity_type.map((el) => el.value) : [],
        //     });
        //     // this.case_lists = data.cases.map((el) => el._id);
        //     this.chartOption6.series[0].data = data.cases.map((el) => ({
        //         name: el._id,
        //         y: el.value,
        //     }));
        //     this.graphLoading.chart7 = false;
        // },
        // async initLinecharts() {
        //     this.graphLoading.chart5 = true;
        //     const { data } = await axios.post("dashboard/timeline-graph", {
        //         case_id: this.selected_case,
        //         risk_categories: this.selected_risk_category,
        //         rag_status: this.selected_rag,
        //         entity: this.selected_subject_name,
        //         domains: this.selected_domain,
        //         source_categories: this.selected_key_facts,
        //         // start_date: this.dateRange[0],
        //         // end_date:this.dateRange[1],
        //         start_date: this.dateRange[0],
        //         end_date: this.dateRange[1],
        //         case_type: this.selected_case_type,
        //         case_status: this.selected_case_status,
        //     });
        //     this.chartOption7.series[0].data = data.timelines
        //         .sort((a, b) => Date.parse(a._id) - Date.parse(b._id))
        //         .map((el) => ({
        //             name: el._id,
        //             y: el.value,
        //             x: Date.parse(el._id),
        //         }));
        //     this.graphLoading.chart5 = false;
        // },

        filterByDate() {
            // for timeline charts
        },
        setDateFilter(days) {
            this.dateRange = [];
            this.saved_dateRange = [];
            let today = new Date();
            let lastWeek = Date.parse(today) - 3600 * 1000 * 24 * days;
            this.dateRange.push(lastWeek, Date.parse(today));
            //this.saved_dateRange.push(lastWeek, Date.parse(today));

            this.handleDatePicker();
            this.handleEditDatePicker();
            this.$refs?.datePicker?.closePopup();
        },
        setEventDateFilter(days) {
            this.eventDateRange = [];
            let today = new Date();
            let lastWeek = Date.parse(today) - 3600 * 1000 * 24 * days;
            this.eventDateRange.push(lastWeek, Date.parse(today));
            //this.saved_dateRange.push(lastWeek, Date.parse(today));

            this.handleEventDatePicker();
            // this.handleEditDatePicker();
            this.$refs?.eventDatePicker?.closePopup();
        },

        handleRiskCategory() {},
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        handlePerPage() {
            this.currentPage = 1;
        },
        handlePerPageEntity() {
            this.currentPageEntity = 1;
        },
        async getTableData() {
            this.isLoading = true;
            const {data} = await axios.post("dashboard/table/raw-data", {
                case_id: this.selected_case,
                entity: this.selected_subject_name,
                entity_type: this.selected_entity_type ? this.selected_entity_type.map((el) => el.value) : [],
                risk_categories: this.selected_risk_category,
                // domains: this.selected_domain,
                rag_status: this.selected_rag,
                case_status: this.selected_case_status,
                case_type: this.selected_case_type,
                // source_categories: this.selected_key_facts,
                start_date: this.dateRange[0],
                end_date: this.dateRange[1],
                skip: (this.currentPage - 1) * this.perPage,
                order_by: this.serverParams.order_by,
                sort_by: this.serverParams.sort_by,
                event_rag_status: this.selected_rag_status,
                event_start_date: this.eventDateRange[0],
                event_end_date: this.eventDateRange[1],
            });
            if (this.currentPage === 1) {
                this.tableRows = data.data;
            } else {
                this.tableRows = uniqBy([...this.tableRows, ...data.data], "id");
            }
            this.isLoading = false;

            // if (this.tableRows.length <= 0)
            // this.totalRows = data.total_records
        },
        // table events
        // onPageChange(params) {
        //     this.updateParams({ page: params.currentPage });
        //     this.getTableData();
        // },
        // onPerPageChange(params) {
        //     this.updateParams({ page: 1, limit: params.currentPerPage });
        //     this.getTableData();
        // },
        onSortChange(params) {
            this.currentPage = 1;
            Object.assign(this.serverParams, {
                sort_by: params[0].field,
                order_by: params[0].type == "none" ? null : params[0].type,
            });
            // this.updateParams({
            //     sort_by: params[0].field,
            //     order_by: params[0].type == "none" ? null : params[0].type,
            // });
            this.getTableData();
        },
        async handlePageChange(page) {
            await this.getTableData();
        },
        expandGraph(chartOption) {
            this.isChartExpanded = true;
            this.chartExpanded = cloneDeep(chartOption);
            this.chartExpanded.chart.height = window.innerHeight - 200;
            (this.chartExpanded.chart.margin = [100, 60, 100, 0]),
                // this.chartExpanded.series.dataLabels.format = "{point.name point.percentage:.1f} %"
                (this.chartExpanded.legend = {
                    align: "right",
                    layout: "vertical",
                    floating: false,
                    alignColumns: true,
                    // maxHeight: 90,
                    itemStyle: {
                        fontSize: 16,
                        fontWeight: 400,
                        marginBottom: 8,
                    },
                    itemWidth: 220,
                });
        },
        closeGraph() {
            this.isChartExpanded = false;
            this.chartExpanded = {};
        },
        getCaseIdLink(case_id) {
            let link = `/ro/${case_id}/case/personalDetails`;
            return link;
        },
        getSourceLink(row) {
            // console.log('row :>> ', row);
            let {entity_type, source_link, case_id, id: keyFactsId} = row;
            let link = source_link;
            if (entity_type === "Case Created") {
                link = `/ro/${case_id}/case/personalDetails`;
            }
            if (entity_type === "Outputs") {
                link = `/ro/${case_id}/case/keyFacts/${keyFactsId}`;
            }
            return link;
        },

        activateReadMore(row, column) {
            if (column == "risk_category") {
                this.$set(row, "readMoreActivated", !row.readMoreActivated);
            } else if (column == "customer_note") {
                this.$set(row, "readMoreActivatedNote", !row.readMoreActivatedNote);
            } else if (column == "source_category") {
                this.$set(row, "readMoreActivatedOutput", !row.readMoreActivatedOutput);
            } else if (column == "relationship") {
                this.$set(row, "readMoreActivatedRelated", !row.readMoreActivatedRelated);
            } else if (column == "entity_value") {
                this.$set(row, "readMoreActivatedEntityValue", !row.readMoreActivatedEntityValue);
            } else if (column == "entity_name") {
                this.$set(row, "readMoreActivatedEntityName", !row.readMoreActivatedEntityName);
            } else if (column == "entity_name") {
                this.$set(row, "readMoreActivatedEntityName", !row.readMoreActivatedEntityName);
            }
        },

        saveFilter() {
            this.$modal.show("saveDashboardFilters");
        },

        closeFilter() {
            this.$modal.hide("saveDashboardFilters");
            this.resetSaveFilter();
        },

        resetSaveFilter() {
            this.selected_case = [];
            this.selected_subject_name = [];
            this.selected_rag = [];
            this.selected_rag_status = [];
            this.selected_risk_category = [];
            this.selected_case_type = [];
            this.selected_case_status = [];
            this.selected_case_list = [];
            this.selected_entity_type = [];
            this.filterName = "";
            this.selected_user = [];
            this.users = [];
            this.alerts = true;
            this.disable = true;
            this.alertFrequencies("yes");
        },

        /*To close edit dashboard filter modal and clear filters*/
        closeEditFilter() {
            this.$modal.hide("editDashboardFilters");
            resetUpdateEditFilter();
        },

        /*To save dashboard filters */

        async dashboardFilters() {
            if (this.filterName != "") {
                if (this.selected_user.length > 0) {
                    this.selected_user.forEach((n) => this.users.push(n.id));
                }
                let today = new Date();
                let startDate = new Date(this.dateRange[0]);
                let endDate = new Date(this.dateRange[1]);
                startDate.setHours(0);
                startDate.setMinutes(0);
                startDate.setSeconds(0);
                startDate.setMilliseconds(0);

                //// SET END DATE TO HAVE CURRENT TIME
                endDate.setHours(today.getHours());
                endDate.setMinutes(today.getMinutes());
                endDate.setSeconds(today.getSeconds());
                let filterData = {
                    case_id: this.selected_case,
                    entity: this.selected_subject_name,
                    risk_categories: this.selected_risk_category,
                    rag_status: this.selected_rag,
                    case_type: this.selected_case_type,
                    case_status: this.selected_case_status,
                    start_date: startDate,
                    end_date: endDate,
                    entity_type: this.selected_entity_type,
                    name: this.filterName,
                    alert: this.alerts,
                    users: this.users,
                    alert_frequency: this.selected_frequency ? this.selected_frequency.toLowerCase() : null,
                    event_rag_status: this.selected_rag_status,
                };
                const url = "/dashboard/views";
                try {
                    const response = axios.post(url, filterData);
                    this.$modal.hide("saveDashboardFilters");
                    this.resetSaveFilter();
                    this.$toast.success("Dashboard view added successfully");
                    this.updateFilters();
                } catch (error) {
                    if (error.response.status === 401) {
                        this.isAuth = true;
                    } else {
                        let message = error.message;
                        this.$toast.error(message);
                    }
                }
            }
        },
        /*To get saved Filters from dashboard */
        async savedFilters() {
            try {
                const response = await axios.get("/dashboard/views");
                this.dashboardSavedFilters = response.data;
                if (this.dashboardSavedFilters.length > 0) this.disableSaved = false;
                else this.disableSaved = true;
            } catch (error) {
                if (error.response.status === 401) {
                    this.isAuth = true;
                } else {
                    let message = error.message;
                    this.$toast.error(message);
                }
            }
        },

        openSavedFilter() {
            if (this.dashboardSavedFilters.length > 0) {
                if (!this.openSavedFilters) {
                    this.openSavedFilters = true;
                } else if (this.openSavedFilters) {
                    this.openSavedFilters = false;
                }
            } else {
                this.openSavedFilters = false;
            }
        },

        handleClickSavedFilters() {
            this.openSavedFilters = false;
        },

        /* To show the data in update filter modal*/

        editSavedFilters(savedFilters) {
            /*Assigning the values of saved filters to data variables */
            this.$modal.show("editDashboardFilters");
            this.editDashboardFilter = true;
            this.filterId = savedFilters.id;
            this.saved_selected_case = savedFilters.case_id;
            this.saved_selected_subject_name = savedFilters.entity;
            this.saved_selected_risk_category = savedFilters.risk_categories;
            this.saved_selected_rag = savedFilters.rag_status;
            this.saved_selected_case_type = savedFilters.case_type;
            this.saved_selected_case_status = savedFilters.case_status;
            this.saved_selected_entity_type = savedFilters.entity_type;
            this.filterName = savedFilters.name;
            this.alerts = savedFilters.alert;
            this.selected_frequency = savedFilters.alert_frequency;
            this.saved_dateRange = [Date.parse(savedFilters.start_date), Date.parse(savedFilters.end_date)];
            if (savedFilters.users.length > 0) {
                this.usersList.forEach((data, index) => {
                    savedFilters.users.forEach((data1, index) => {
                        if (data.id == data1) {
                            if (!this.saved_selected_user.includes(data)) {
                                this.saved_selected_user.push(data);
                            }
                        }
                    });
                });
            }
            if (savedFilters.users.length == 0) {
                this.saved_selected_user = this.usersList[0];
            }
            if (this.selected_frequency == null || this.selected_frequency == "") {
                this.selected_frequency = this.alertFrequency[0];
            }
        },

        /*To update the dashboard filter */
        async updateSavedFilter() {
            console.log(this.saved_selected_user);
            if (this.saved_selected_user.length > 0) {
                this.saved_selected_user.map((n) => this.users.push(n.id));
            }
            let filterData = {
                case_id: this.saved_selected_case,
                entity: this.saved_selected_subject_name,
                risk_categories: this.saved_selected_risk_category,
                rag_status: this.saved_selected_rag,
                case_type: this.saved_selected_case_type,
                case_status: this.saved_selected_case_status,
                start_date: this.saved_dateRange[0],
                end_date: this.saved_dateRange[1],
                entity_type: this.saved_selected_entity_type,
                name: this.filterName,
                alert: this.alerts,
                users: this.alerts ? this.users : [],
                alert_frequency: this.alerts ? (this.selected_frequency ? this.selected_frequency.toLowerCase() : null) : null,
                id: this.filterId,
            };

            const url = "/dashboard/views";
            try {
                const response = await axios.put(url, filterData);
                this.$modal.hide("editDashboardFilters");
                this.$toast.success("Dashboard view updated successfully");
                this.resetUpdateEditFilter();
                this.updateFilters();
            } catch (error) {
                if (error.response.status === 401) {
                    this.isAuth = true;
                } else {
                    let message = error.message;
                    this.$toast.error(message);
                }
            }
        },
        async getBookmarks() {
            this.bookmarkFilters = [];
            const accountId = this.$store.getters.getAccountId;
            let result;
            if (this.$route?.query?.dashboard_id) result = this.getAssignedDashboards.find((res) => res.dashboardId === this.$route.query.dashboard_id);
            else result = this.getAssignedDashboards.find((res) => res.visited);
            if (result) {
                const response = await axios.get(`/dashboard/bookmarks/${accountId}/${result.dashboardId}`);
                if (response.data.length > 0) {
                    this.bookmarkList = response.data;
                    if (this.$route.query.bookmark_id) {
                        let id = this.$route.query.bookmark_id;
                        let filters = response.data;
                        let index = filters.findIndex((el) => el.id === id);
                        if (index > -1) {
                            this.bookmarkFilters = filters[index];
                        } else this.$toast.error("You are looking for a bookmark that does not exist or has been deleted");
                    }
                    // else if(this.bookmarkId) {
                    //     let filters = response.data
                    //     let index = filters.findIndex(el => el.id === this.bookmarkId);
                    //     if(index>-1) {
                    //         this.bookmarkFilters = filters[index];
                    //         await this.handleDashboardFilters();
                    //     }

                    // }
                } else this.bookmarkList = [];
            }
        },

        resetUpdateEditFilter() {
            this.saved_selected_case = [];
            this.saved_selected_subject_name = [];
            this.saved_selected_rag = [];
            this.saved_selected_risk_category = [];
            this.saved_selected_case_type = [];
            this.saved_selected_case_status = [];
            this.saved_selected_case_list = [];
            this.saved_selected_entity_type = [];
            this.saved_filterName = "";
            this.saved_selected_user = [];
            this.users = [];
            this.alerts = true;
            this.editDashboardFilter = false;
        },

        async handleDeleteView() {
            this.deleteView = true;
            if (this.deleteView == true) {
                try {
                    const response = await axios.delete(`/dashboard/views/${this.dashboardfilterId}`);
                    this.$modal.hide("confirm_delete");
                    this.$toast.success("Dashboard view deleted successfully");
                } catch (error) {
                    if (error.response.status === 401) {
                        this.isAuth = true;
                    } else {
                        let message = error.message;
                        this.$toast.error(message);
                    }
                }
            }
        },

        deleteSavedFilters(savedFilter) {
            this.$modal.show("confirm_delete");
            this.dashboardfilterId = savedFilter.id;
        },

        startLoader() {
            this.loaderController.success = false;
            this.loaderController.loading = true;
        },
        successLoader() {
            this.loaderController.success = true;
            this.loaderController.loading = false;
        },
        resetLoader() {
            this.loaderController.success = false;
            this.loaderController.loading = false;
        },

        getUserFullName(user) {
            return user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1) + " " + user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1);
        },

        async saveBookMarks(filterName) {
            await this.getFilters();
            const accountId = this.$store.getters.getAccountId;
            const filterData = this.filterParams;
            const result = this.getAssignedDashboards.find((res) => res.visited);
            this.bookmarkExits = false;
            const url = `/dashboard/bookmarks`;
            let payload = {
                name: filterName,
                filters: filterData,
                company: accountId,
                dashboard_id: result.dashboardId,
            };
            try {
                const response = await axios.post(url, payload);
                if (response && response.data) {
                    EventBus.$emit("savedbookmark", response.data);
                    this.$toast.success(response.data.message);
                }
            } catch (error) {}
        },

        async updateBookMarks(filterName, bookmarkId) {
            await this.getFilters();
            const accountId = this.$store.getters.getAccountId;
            const filterData = this.filterParams;
            const result = this.getAssignedDashboards.find((res) => res.visited);
            const url = `/dashboard/bookmarks/${bookmarkId}`;
            let payload = {
                name: filterName,
                filters: filterData,
                company: accountId,
                dashboard_id: result.dashboardId,
            };
            try {
                const response = await axios.put(url, payload);
                if (response && response.data) {
                    this.$toast.success(response.data.message);
                    EventBus.$emit("savedbookmark", response.data);
                }
            } catch (error) {}
        },

        async getAllUsers() {
            const accountId = this.$store.getters.getAccountId;
            const url = `/api/v1/accounts/${accountId}/users`;
            try {
                this.startLoader();
                const response = await asAxios.get(url);
                this.usersList = response.data;
                if (this.usersList.length > 0) {
                    this.usersList.forEach((user) => {
                        user.full_name = this.getUserFullName(user);
                    });
                }
                this.resetLoader();
            } catch (error) {
                this.resetLoader();
                if (error.response.status === 401) {
                    this.isAuth = true;
                } else {
                    let message = error.message;
                    this.$toast.error(message);
                }
            }
        },

        customLabel(option) {
            return `${option.full_name}`;
        },

        selectedUsers(user) {
            this.users.push(user.id);
        },

        alertFrequencies(alert) {
            if (alert == "yes" || this.alert == "true") {
                this.alerts = true;
                if (this.selected_frequency == "" && this.selected_user.length == 0) {
                    this.selected_frequency = this.alertFrequency[0];
                    this.selected_user = this.usersList[0];
                }
            } else {
                this.alerts = false;
                this.selected_frequency = "";
                this.selected_user = [];
            }
        },
        enableButton() {
            if (this.selected_entity_type.length > 0 || this.selected_case.length > 0 || this.selected_subject_name.length > 0 || this.selected_risk_category.length > 0 || this.selected_rag.length > 0 || this.selected_case_type.length > 0 || this.selected_case_status.length > 0 || this.selected_rag_status.length > 0) this.disable = false;
            else this.disable = true;
        },
        routeBack() {
            this.$router.push({name: "Dashboard List"});
        },
    },
    async mounted() {
        this.Dashboard = true;
        this.loadingDashboard = true;
        this.bookmarkFilters = [];
        // EventBus.$on("handleDashboardSelection", async (dashboardId) => {
        //     await this.handleDashboardView()
        // });
        await this.getCompanyDashboards();
        if (this.getAssignedDashboards.length > 0) {
            this.$router.push({name: "Bi-Dashboard-View", params: {id: this.getAssignedDashboards.find((res) => res.visited).dashboardId}});
            return;
        }
        // await this.getBookmarks();
        // const dashboardView = await this.handleDashboardView()
        // EventBus.$emit("handleBookmarkList",this.bookmarkList);
        this.Dashboard = false;
        this.loadingDashboard = false;
        this.setDateFilter(7);
        if (localStorage.getItem("riskFilters") !== null) {
            //...
            const filters = JSON.parse(localStorage.getItem("riskFilters"));
            let {case_id, subjectName, rag, risk_categories, case_state, entity_type, dateRange, event_rag_status, eventDateRange} = filters;
            this.selected_case = case_id;
            this.selected_subject_name = subjectName;
            this.selected_rag = rag;
            this.selected_risk_category = risk_categories;
            this.selected_case_status = case_state;
            this.dateRange = dateRange;
            this.selected_entity_type = entity_type;
            this.selected_rag_status = event_rag_status;
            this.eventDateRange = eventDateRange;
            // this.selected_key_facts = filters.sources;
            // case_id: this.selected_case,
            // case_state: this.selected_case_status,
            // case_type: this.selected_case_type,
            // subjectName: this.selected_subject_name,
            // risk_categories: this.selected_risk_category,
            // rag: this.selected_rag,
            // entity_type: this.selected_entity_type,
        }
        await this.initCharts();
        await this.updateFilters();
        await this.getAllEntities();
        await this.getTableData();
        await this.getPagination();
        await this.getAllUsers();
        await this.savedFilters();
        this.alertFrequencies("yes");
    },
    computed: {
        ...mapGetters(["getPermissions", "getLicenses", "checkPermissionsFetched", "getUserAuthDetails", "getAccountId"]),
        ...mapState("dashboard", ["caseListPieChart", "caseStatusPieChart", "domainsPieChart", "ragStatusPieChart", "riskCategoriesPieChart", "sourceCategoriesPieChart", "timeline"]),
        ...mapGetters("dashboard", ["getAssignedDashboards", "getDashboardId"]),
        selectedAccount() {
            return this.$store.getters.getAccountId;
        },
        getTotalPages() {
            return Math.ceil(this.total_records / this.perPage);
        },
        getTotalPagesEntity() {
            return Math.ceil(this.allEntitiesList.length / this.perPageEntity);
        },
        filteredList() {
            let list = cloneDeep(this.tableRows);
            return uniqBy(list);
        },
        permissionsFetched() {
            return this.checkPermissionsFetched;
        },
        isAuth() {
            return this.getLicenses.includes("case_and_risk_dashboard__case_data");
        },
        allEntitiesList() {
            return this.allEntities.filter((el) => el.entity?.toLowerCase().includes(this.searchEntity.toLowerCase()));
        },
        caseStatus() {
            const sorter = (a, b) => {
                if (this.selected_case_status.includes(a)) {
                    return -1;
                }
                if (this.selected_case_status.includes(b)) {
                    return 1;
                }
                return 0;
            };
            let status = this.case_status.sort(sorter);
            return [{group: "Case Status", categories: status}];
        },
        caseId() {
            const sorter = (a, b) => {
                if (this.selected_case.includes(a)) {
                    return -1;
                }
                if (this.selected_case.includes(b)) {
                    return 1;
                }
                return 0;
            };
            let caseIds = this.case_id.sort(sorter);
            return [{group: "Case Id", categories: caseIds}];
        },
        caseRag() {
            const sorter = (a, b) => {
                if (this.selected_rag.includes(a)) {
                    return -1;
                }
                if (this.selected_rag.includes(b)) {
                    return 1;
                }
                return 0;
            };
            let rag = this.rag.sort(sorter);
            return [{group: "Case Rag", categories: rag}];
        },
        caseEntityName() {
            const sorter = (a, b) => {
                if (this.selected_subject_name.includes(a)) {
                    return -1;
                }
                if (this.selected_subject_name.includes(b)) {
                    return 1;
                }
                return 0;
            };
            let entityName = this.subjectName.sort(sorter);
            return [{group: "Entity Name", categories: entityName}];
        },
        caseEventCategory() {
            const sorter = (a, b) => {
                if (this.selected_risk_category.includes(a)) {
                    return -1;
                }
                if (this.selected_risk_category.includes(b)) {
                    return 1;
                }
                return 0;
            };
            let events = this.risk_categories.sort(sorter);
            return [{group: "Event Category", categories: events}];
        },
        caseEventType() {
            const sorter = (a, b) => {
                if (this.selected_entity_type.includes(a)) {
                    return -1;
                }
                if (this.selected_entity_type.includes(b)) {
                    return 1;
                }
                return 0;
            };
            let eventType = this.entityType.sort(sorter);
            return [{group: "Event Type", categories: eventType}];
        },
        caseRagStatus() {
            const sorter = (a, b) => {
                if (this.selected_rag_status.includes(a)) {
                    return -1;
                }
                if (this.selected_rag_status.includes(b)) {
                    return 1;
                }
                return 0;
            };
            let ragStatus = this.ragStatus.sort(sorter);
            return [{group: "Rag Status", categories: ragStatus}];
        },
        handleDashboardSelect() {
            if (this.getAssignedDashboards.length > 0) {
                return true;
            } else {
                return false;
            }
        },
    },
    async created() {
        // await this.getFilters()
        // await this.fetchPermissions();
        EventBus.$on("frequency", async (data) => {
            await this.saveBookMarks(data);
        });
        EventBus.$on("updateFrequency", async (name, id) => {
            await this.updateBookMarks(name, id);
        });
        // EventBus.$on("dashboardFilters", async (id) => {
        //     this.bookmarkId = id;
        //     await this.getBookmarks();
        // });
    },
    watch: {
        searchEntity() {
            this.currentPageEntity = 1;
        },
    },
};
