import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import titleMixin from "@/mixins/titleMixin";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import Loader from "@/components/loader";
import axios from "../../axios/user";
import Vue from "vue";
import Toast from "vue-toastification";
import subSidepanel from "@/components/sub-sidepanel";
import {default as saasAxios} from "../../axios";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import caseApi from "@/axios";

// Dynamic imports for components that are not used on first load
const Modal = () => import("@/components/modal");
const Button = () => import("@/components/button");
const Input = () => import("@/components/input");
const Multiselect = () => import("@/components/vue-multiselect");
const Tag = () => import("@/components/tag");
const Table = () => import("@/components/table");
const Confirmation = () => import("@/components/confirmation");
const Tags = () => import("@/components/tags");
const AlertBox = () => import("@/containers/alert-box");
const UserDetailsCard = () => import("./userDetailsCard.vue");

Vue.use(Toast);

export default {
    title() {
        return `Users`;
    },
    name: "neo-users",
    mixins: [titleMixin],
    components: {
        "neo-modal": Modal,
        "neo-input": Input,
        "neo-button": Button,
        "neo-tag": Tag,
        "neo-table": Table,
        "neo-confirmation": Confirmation,
        multiselect: Multiselect,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-tags": Tags,
        "neo-alert": AlertBox,
        "neo-loader": Loader,
        UserDetailsCard,
        subSidepanel,
    },
    props: {},
    data() {
        return {
            showDeleteUserPrompt: false,
            groups: [
                {group_id: 1, group: "admin"},
                {group_id: 2, group: "analyst"},
            ],
            cases: [],
            selectedUserIndex: 0,
            loaderController: {
                loading: false,
                success: false,
            },
            alertMessage: {
                success: null,
                error: null,
            },
            searchUserFilter: "",
            addUser: false,
            accountUri: "",
            addUserEmail: "",
            editUser: false,
            orgGroups: [],
            editUserNewGroups: [],
            editUserRemovedGroup: [],
            editUserControler: {},
            editUserIndex: null,
            users: [],
            account_id: "",
            headers: [
                {
                    text: "Name",
                    value: "full_name",
                },
                {
                    text: "Email",
                    value: "email",
                },
                {
                    text: "Active",
                    value: "is_active",
                },
                {
                    text: "Groups",
                    value: "groups",
                },
            ],
            itemKey: "id",
            deleteUser: null,
            deleteIndex: null,
            selectable: false,
            hasAction: true,
            emailAddressError: false,
            allCases: [],
            allPermissions: [],
            selectedCases: [],
            selectedPermissions: "",
            allResources: [],
            selectedResources: null,
            allResourcesPerm: [],
            selectedResourcesPerm: "",
            allUsersResources: [],
            selectedUserResources: null,
            newlyAddedUserPermission: [],
            isAuth: false,
        };
    },
    computed: {
        colWidth() {
            let cols = this.headers.length;
            if (this.selectable) {
                cols += 1;
            }
            if (this.hasAction) {
                cols += 1;
            }
            return `${100 / cols}%`;
        },
        selectedUser() {
            return this.users[this.selectedUserIndex];
        },
    },
    async mounted() {
        await this.getAccountGroups();
        await this.getAllUsers();
        this.handleUser(this.users[0], 0);
        // this.testRecursiveCall();
    },
    methods: {
        // testRecursiveCall(){
        //     setTimeout(async () => {
        //         this.users = [];
        //         await this.getAllUsers();
        //         this.handleUser(this.users[0], 0);
        //         this.testRecursiveCall();
        //     }, 5000)
        // },
        async getUsersCases(userIds) {
            const url = `/permissions/user-case-mapping`;
            try {
                let response = await caseApi.post(url, {user_ids: userIds});
                return response.data;
            } catch (error) {
                return {};
            }
        },
        setActiveUser(index) {
            this.selectedUserIndex = index;
            // this.selectedUser = this.users[index];
        },
        getActiveUser() {
            return this.users[this.selectedUserIndex];
        },
        username(user) {
            if (user.full_name === " ") {
                return user.email.split("@")[0];
            } else {
                return user.full_name;
            }
        },
        toggleAddUser() {
            if (this.addUser) {
                this.addUser = false;
                this.loaderController.success = false;
            } else {
                this.addUser = !this.addUser;
            }
        },
        toggleAlertBox({success = null, error = null}) {
            this.alertMessage.success = success;
            this.alertMessage.error = error;
            setTimeout(() => {
                this.alertMessage.success = null;
                this.alertMessage.error = null;
            }, 3000);
        },
        startLoader() {
            this.loaderController.success = false;
            this.loaderController.loading = true;
        },
        successLoader() {
            this.loaderController.success = true;
            this.loaderController.loading = false;
        },
        resetLoader() {
            this.loaderController.success = false;
            this.loaderController.loading = false;
        },
        resetEditUser() {
            if (this.editUserControler.groups) {
                for (const group of this.editUserControler.groups) {
                    delete group.error;
                }
            }

            this.editUserNewGroups = [];
            this.editUserControler = {};
            this.editUserRemovedGroup = [];
        },
        openEditUser(user) {
            this.resetEditUser();
            this.editUserControler = user;
            this.editUser = true;
        },

        closeEditUser() {
            this.editUser = false;
            this.resetEditUser();
            this.resetLoader();
        },
        validateEmail(email) {
            const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return res.test(String(email).toLowerCase());
        },

        async getAccountGroups() {
            this.startLoader();

            const accountId = this.$store.getters.getAccountId;
            const url = `/api/v1/accounts/${accountId}/groups`;

            try {
                const response = await axios.get(url);
                this.orgGroups = response.data;
            } catch (error) {
                if (error.response.status === 401) {
                    this.isAuth = true;
                } else {
                    let message = error.message;
                    this.$toast.error(message);
                }
            }
        },

        getUserFullName(user) {
            return user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1) + " " + user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1);
        },
        async getAllUsers() {
            const accountId = this.$store.getters.getAccountId;
            const url = `/api/v1/accounts/${accountId}/users`;
            try {
                const response = await axios.get(url);
                this.users = response.data;
                this.users.forEach((user) => {
                    user.full_name = this.getUserFullName(user);
                });
                this.resetLoader();
            } catch (error) {
                this.resetLoader();
                if (error.response.status === 401) {
                    this.isAuth = true;
                } else {
                    let message = error.message;
                    this.$toast.error(message);
                }
            }
        },
        async resendInvitaion() {
            try {
                const uri = `api/v1/accounts/${this.$store.getters.getAccountId}/users`;
                this.startLoader();
                let response = await axios.post(uri, {email: this.selectedUser.email});
                this.successLoader();
                this.$toast.success("Invitation sent");
            } catch (error) {
                this.resetLoader();
                let message = error.message;
                this.$toast.error(message);
            }
        },
        async inviteUser() {
            if (this.addUserEmail && this.validateEmail(this.addUserEmail)) {
                try {
                    const uri = `api/v1/accounts/${this.$store.getters.getAccountId}/users`;
                    this.startLoader();
                    let response = await axios.post(uri, {email: this.addUserEmail});
                    this.successLoader();
                    this.$toast.success("User Added Successfully");
                    this.addUser = false;
                    let user = response.data;
                    user["full_name"] = this.getUserFullName(user);
                    this.users.push(user);
                } catch (error) {
                    console.error(error);
                    this.resetLoader();
                    this.addUser = !this.addUser;
                    this.addUserEmail = null;
                    let message = error.response.data.detail || error.message;
                    this.$toast.error(message);
                }
            } else {
                this.emailAddressError = true;
                setTimeout(() => {
                    this.emailAddressError = false;
                }, 3000);
            }
        },

        async updateUser() {
            if (this.editUserNewGroups.length > 0) {
                try {
                    const uri = `${this.accountUri}/users/${this.editUserControler.id}`;
                    this.startLoader();
                    await axios.post(uri, this.editUserNewGroups);
                    this.editUserNewGroups = [];
                    this.successLoader();
                    this.editUser = false;
                } catch (error) {
                    this.resetLoader();
                    this.editUserControler = {};
                    this.editUser = true;
                    let message = error.message;
                    this.$toast.error(message);
                }
            }

            if (this.editUserRemovedGroup.length > 0) {
                try {
                    const url = `${this.accountUri}/users/${this.editUserControler.id}/groups`;

                    this.startLoader();
                    const response = await axios.delete(url, {data: this.editUserRemovedGroup});
                    for (const g of response.data) {
                        this.editUserControler.groups.push(g);
                    }
                    this.editUserRemovedGroup = [];
                    this.successLoader();
                } catch (error) {
                    this.resetLoader();
                    let message = error.message;
                    this.$toast.error(message);
                }
                await this.getAllUsers();
            }
            await this.getAllUsers();
        },

        async removeUser() {
            try {
                let user = this.users[this.selectedUserIndex];
                const accountId = this.$store.getters.getAccountId;
                const url = `api/v1/accounts/${accountId}/users/${user.id}?status=${user.status}`;
                let response = await axios.delete(url);
                this.$toast.success("User deleted successfully.");
                this.users.splice(this.selectedUserIndex, 1);
                // this.showDeleteUserPrompt = false
                this.$modal.hide("confirm_delete");
                if (response.success) {
                }
                // this.deleteUser = null;
                // this.deleteIndex = null;
            } catch (error) {
                setTimeout(() => (this.alertMessage.error = null), 3000);
                // this.deleteUser = null;
                // this.deleteIndex = null;
                let message = error.message;
                this.$toast.error("user deletion failed");
            }
        },
        async beforeOpen() {
            if (!this.allPermissions.length || !this.allResources.length || !this.allCases.length || !this.allResourcesPerm.length) {
                await this.fetchAllCases();
                await this.fetchAllResources();
                await this.fetchAllpermissions();
                await this.fetchAllResourceScope();
                await this.fetchAllUserResources();
            } else return;
        },
        async addGroup(group) {
            for (const g of this.editUserControler.groups) {
                if (g.id === group.id) {
                    return;
                }
            }

            this.editUserNewGroups.push(group);
            this.editUserControler.groups.push(group);
        },

        async removeGroup(group, group_index) {
            let newGroup = false;
            let newGroupIndex = null;
            for (const [index, g] of this.editUserNewGroups.entries()) {
                if (g.id === group.id) {
                    newGroup = true;
                    newGroupIndex = index;
                    break;
                }
            }
            this.editUserControler.groups.splice(group_index, 1);
            if (newGroup) {
                this.editUserNewGroups.splice(newGroupIndex, 1);
            } else {
                this.editUserRemovedGroup.push(group);
            }
        },
        async fetchAllCases() {
            const {data} = await saasAxios.get("case-info/case-list");
            this.allCases = data.cases;
        },
        async fetchAllResources() {
            const {data} = await saasAxios.get("resources");
            const resources = data.map((e) => ({
                ...e,
                name: e.name
                    .split("_")
                    .join(" ")
                    .toString()
                    .replace(/^\w/, (c) => c.toUpperCase()),
            }));
            this.allResources = resources;
        },
        async fetchAllpermissions() {
            const {data} = await saasAxios.get("resources/scopes/case");
            const resources = data.map((e) => {
                return {
                    ...e,
                    label: e.name.split("#")[1].split(":")[0].split("--").join(" ").toUpperCase(),
                };
            });
            this.allPermissions = resources;
        },
        async fetchAllResourceScope() {
            const {data} = await saasAxios.get("resources/scopes");
            const resources = data.filter((e) => !e.includes("case")).map((e) => e.toUpperCase());
            this.allResourcesPerm = resources;
        },
        async fetchAllUserResources() {
            const {data} = await axios.get(`/api/v1/accounts/${this.$store.getters.getAccountId}/resources`);
            const user_res = data
                .filter((e) => e.name.includes(":iam"))
                .map((el) => ({
                    ...el,
                    label: el.name
                        .split(".")[0]
                        .split("_")
                        .join(" ")
                        .toString()
                        .replace(/^\w/, (c) => c.toUpperCase()),
                }));
            this.allUsersResources = user_res;
        },
        handleAddUser() {
            this.addUser = true;
        },
        handleUser(user, index, oldIndex = null) {
            if (oldIndex) {
                this.selectedUserIndex = oldIndex;
                this.setActiveUser(oldIndex);
            } else {
                this.selectedUserIndex = index;
                this.setActiveUser(index);
            }

            // this.getAllUserCaseMapping();
        },
        handleResetUser() {
            this.selectedUserIndex = "";
            this.setActiveUser("");
        },
        async assignUserResources() {
            if (this.selectedResources && this.selectedResourcesPerm) {
                const case_ids = this.selectedCases.map((e) => e.case_id);
                const body = {
                    user_id: this.$store.getters.getAccountId,
                    permission_name: this.selectedResourcesPerm?.toLowerCase(),
                    resource: this.selectedResources?.name?.toLowerCase(),
                    case_ids: this.selectedCases.map((e) => e.case_id),
                };
                let account_uri = "permissions";
                try {
                    const resp = await saasAxios.post(account_uri, body);
                    this.$modal.hide("assign_case");
                    this.$toast.success(`Assigned resource permission`);
                    this.selectedResources = null;
                    this.selectedResourcesPerm = "";
                    this.selectedCases = [];
                } catch (error) {
                    const msg = error?.detail;
                    this.$toast.error(msg);
                }
            } else this.$toast.error(`Please select cases and permissions`);
        },
        async assignUserPermission() {
            const obj = [
                {
                    user_id: this.users[this.selectedUserIndex].id,
                    account_id: this.$store.getters.getAccountId,
                    resource: this.selectedUserResources?.name?.toLowerCase(),
                    action: this.selectedResourcesPerm?.toLowerCase(),
                },
            ];

            try {
                let res = await axios.post(`api/v1/accounts/${this.$store.getters.getAccountId}/user-permissions`, obj);
                this.newlyAddedUserPermission = res.data;
                this.$modal.hide("assign_case");
                this.$toast.success(`Assigned to ${this.selectedUser.first_name}`);
            } catch (error) {
                this.$toast.error(error);
            }
        },
        async getAllUserCaseMapping() {
            let user_id = this.users[this.selectedUserIndex].id;
            const obj = {
                user_ids: [user_id],
            };
            const {data} = await saasAxios.post("permissions/user-case-mapping", obj);
            if (data) {
                this.cases = data[user_id] ? data[user_id] : [];
            }
        },
        async handleAssignCase() {
            if (this.selectedPermissions && this.selectedCases.length) {
                try {
                    const case_ids = this.selectedCases.map((e) => e.case_id);
                    const obj = {
                        user_id: this.selectedUser.id,
                        permission_name: this.selectedPermissions.name,
                        resource: "case",
                        case_ids: case_ids,
                    };
                    let res = await saasAxios.post("permissions", obj);
                    this.selectedCases = [];
                    this.selectedPermissions = null;
                    this.$modal.hide("assign_case");
                    this.$toast.success(`Assigned to ${this.selectedUser.first_name}`);
                } catch (error) {
                    this.$toast.error(error);
                }
            } else {
                this.$toast.error(`Please select cases and permissions`);
                return;
            }
        },
    },
};
